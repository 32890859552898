<template>
  <div class="main" id="large-screen-index" @click="TurnOnSound = true">
    <div class="head">
      <div class="head_left">
        <span class="head_left_item head_left_date">{{ time.split(' ')[0] }}</span>
        <span class="head_left_item head_left_date">{{ week }}</span>
        <span class="head_left_item head_left_time">{{ time.split(' ')[1] }}</span>
      </div>
      <span class="head_title">智慧养老服务平台</span>
      <i @click="back" class="el-icon-switch-button head-right"></i>
    </div>
    <div class="body">
      <div class="body_left">
        <!-- 住院人员情况统计图 -->
        <div class="item_1">
          <div class="item_1_head">
            <div class="item_1_head_line"></div>
            <div class="item_1_head_con">
              <img class="item_1_head_con_image" src="../../assets/large-screen/to_right.png" alt="logo">
              <span class="item_1_head_con_title">人员情况统计</span>
            </div>
          </div>
          <el-carousel :interval="5000" arrow="never" class="echart-box">
            <el-carousel-item>
              <div id="EchartId-1" class="pie-chart"></div>
            </el-carousel-item>
            <el-carousel-item>
              <div id="EchartId-2" class="pie-chart"></div>
            </el-carousel-item>
            <el-carousel-item>
              <div id="EchartId-3" class="pie-chart"></div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="item_2">
          <div class="item_1_head">
            <div class="item_1_head_line"></div>
            <div class="item_1_head_con">
              <img class="item_1_head_con_image" src="../../assets/large-screen/to_right.png" alt="logo">
              <span class="item_1_head_con_title">今日值班人员信息</span>
            </div>
          </div>
          <div class="item_2_table">
            <div class="item_2_table_labelline">
              <div class="flex-1 text-center">值班日期</div>
              <div class="flex-1 text-center">值班员</div>
              <div class="flex-1 text-center">角色</div>
            </div>
            <div class="item_2_table_con">
              <div class="item_2_table_line" v-for="(item, index) in duty" :key="index">
                <div class="flex-1 font-2 text-center">
                  <span>{{ item.shift_time }}</span>
                </div>
                <div class="flex-1 font-2 flex justify-content-c">
                  <p style="width: 30%;text-align: justify;text-align-last: justify;">
                    {{ item.adminer }}
                  </p>
                </div>
                <div class="flex-1 font-2 text-center">{{ item.user.roles[0] ? item.user.roles[0].title : '' }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item_3">
          <div class="item_1_head">
            <div class="item_1_head_line"></div>
            <div class="item_1_head_con">
              <img class="item_1_head_con_image" src="../../assets/large-screen/to_right.png" alt="logo">
              <span class="item_1_head_con_title">风险处理</span>
            </div>
          </div>
          <div class="item_3_table">
            <div class="item_3_table_labelline">
              <div class="label_3">申请人</div>
              <div class="label_4">房间</div>
              <div class="label_4_2 text-c">类型</div>
              <div class="label_5">预警时间-处理时间</div>
              <div class="label_6" v-if="operationAuth" style="justify-content: center;">处理人</div>
              <div class="label_6" v-else style="justify-content: center;">状态</div>
            </div>
            <div class="item_3_table_con">
              <div class="item_3_table_line" v-for="(item, index) in sosHis" :key="index">
                <div class="label_3 font-2">{{ item.member ? item.member.realname : '' }}</div>
                <div class="label_4 font-2">{{ item.address }}</div>
                <div class="label_4_2 font-2 text-c">{{ item.type ? SOStypeDict[item.type].name : '' }}
                </div>
                <div class="label_5 font-2">
                  <span>{{ item.created_at }}</span>
                  <span style="transform: scale(.5);">|</span>
                  <span>{{ item.updated_at }}</span>
                </div>
                <div class="label_3 font-2">
                  <span v-if="!operationAuth">{{ item.status ? sosHisStatusDict[item.status].name : '' }}</span>
                  <span v-else-if="item.status === 2">{{ item.user ? item.user.name : '' }}</span>
                  <span v-else-if="operationAuth && item.status != 2" class="btn-1"
                    style="background-color: #01C2FF;padding: 0 5px;" @click="toOtherPath('/safety/alarm')">处理</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body_mid">
        <div class="item_4">
          <div class="item_4_con">
            <div class="item_4_con_left">
              <div class="item_4_head">
                <div class="item_4_head_line"></div>
                <div class="item_4_head_con">
                  <div class="row">
                    <img class="item_4_head_con_image" src="../../assets/large-screen/to_right.png" alt="logo">
                    <span class="item_4_head_con_title">风险预警</span>
                  </div>
                  <div class="row-2">
                    <span class="open-sound">开启报警音：</span>
                    <el-switch v-model="TurnOnSound" active-color="#13ce66" inactive-color="#ff4949" size="mini"
                      :disabled="TurnOnSound"></el-switch>
                  </div>
                </div>
              </div>
              <div class="table_label">
                <div class="label_12">报警人</div>
                <div class="label_13">房间</div>
                <div class="label_13-2">类型</div>
                <div class="label_14" style="justify-content: center;">操作</div>
              </div>
              <div class="table_con">
                <div class="table_line" v-for="(item, index) in sos" :key="index">
                  <div class="label_12">{{ item.member ? item.member.realname : '' }}</div>
                  <div class="label_13">{{ item.address }}</div>
                  <div class="label_13-2">{{ SOStypeDict[item.type].name }}</div>
                  <div class="label_14" style="justify-content: center;">
                    <span class="btn-2" v-if="operationAuth && item.member" style="background-color: #00AA28;"
                      @click="callVideo(item.member_id, item.id, 'sos')">连线</span>
                    <span class="btn-2" v-if="operationAuth && !item.member" @click="handleRiskWarning(item.id)"
                      style="background-color: #01C2FF;">处理</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="item_4_con_right">
              <div class="top-dialog" v-if="sosShow">
                <div class="top-dialog-con" :class="startFlag ? 'star-bor' : 'static-bor'">
                  <div class="top-dialog-con-head">
                    <div class="row">
                      <img class="top-dialog-con-head-logo" src="../../assets/large-screen/smallBell.png" alt="警告">
                      <span class="top-dialog-con-head-text">sos紧急求助</span>
                    </div>
                    <img class="top-dialog-con-head-logo" src="../../assets/large-screen/fork.png" alt="警告"
                      @click="closePopup">
                  </div>
                  <div class="top-dialog-con-line">
                    <img class="top-dialog-con-line-logo" :src="sosInfo.photo">
                    <div class="top-dialog-con-line-name">{{ sosInfo.realname }}</div>
                  </div>
                  <div class="top-dialog-con-desc" style="justify-content: space-between;">
                    <span class="top-dialog-con-desc-text">性别：{{ sosInfo.sex }}</span>
                    <span class="top-dialog-con-desc-text">手机：{{ sosInfo.mobile }}</span>
                  </div>
                  <div class="top-dialog-con-desc">
                    <span class="top-dialog-con-desc-text">生日：{{ sosInfo.birthday }}</span>
                  </div>
                  <div class="top-dialog-con-desc">
                    <span class="top-dialog-con-desc-text">用药历史：{{ sosInfo.medication }}</span>
                  </div>
                  <div class="top-dialog-con-desc">
                    <span class="top-dialog-con-desc-text">疾病史：{{ sosInfo.disease }}</span>
                  </div>
                  <div class="top-dialog-con-desc">
                    <span class="top-dialog-con-desc-text">住址：{{ sosInfo.address }}</span>
                  </div>
                  <div class="top-dialog-con-desc" style="margin: 10px auto;">
                    <span class="btn-1" style="background-color: #00AA28;margin: 0 auto;"
                      @click="callVideo(sosInfo.userId, sosInfo.alarmId)">连线</span>
                  </div>
                </div>
              </div>
              <div class="top-dialog2" v-else-if="sosOtherShow">
                <!-- 其他地方sos的报警 -->
                <div class="other-sos">
                  <div class="other-sos-con" :class="startFlag ? 'star-bor' : 'static-bor'">
                    <div class="other-sos-head">
                      <div class="row">
                        <img class="other-sos-head-logo" src="../../assets/large-screen/smallBell.png" alt="警告">
                        <span class="other-sos-head-text">sos紧急求助</span>
                      </div>
                      <img class="other-sos-head-logo" src="../../assets/large-screen/fork.png" alt="关闭"
                        @click="closePopup">
                    </div>
                    <div class="other-sos-head">
                      <div class="row">
                        <img class="other-sos-head-logo2" src="../../assets/large-screen/location.png" alt="定位">
                        <span class="other-sos-head-text2">{{ sosOtherInfo.title }}</span>
                      </div>
                      <span></span>
                    </div>
                    <img class="other-sos-local" :src="sosOtherInfo.photo" alt="地图">
                  </div>
                </div>
              </div>
              <!-- sos弹窗不显示时，占位dom -->
              <div class="top-dialog2" v-else></div>
              <div class="bottom-dialog" v-if="gasShow">
                <div class="bottom-dialog-con" :class="startFlag ? 'star-bor' : 'static-bor'">
                  <div class="bottom-dialog-con-head">
                    <div class="row">
                      <img class="bottom-dialog-con-head-logo" src="../../assets/large-screen/smallBell.png" alt="警告">
                      <span class="bottom-dialog-con-head-text">燃气警报</span>
                    </div>
                    <img class="bottom-dialog-con-head-logo" src="../../assets/large-screen/fork.png" alt="警告"
                      @click="closePopup">
                  </div>
                  <div class="bottom-dialog-con-line">
                    <img class="bottom-dialog-con-line-logo" :src="gasInfo.photo">
                    <div class="bottom-dialog-con-line-name">{{ gasInfo.realname }}</div>
                  </div>
                  <div class="bottom-dialog-con-desc">
                    <span class="bottom-dialog-con-desc-text">房间号：{{ gasInfo.address }}</span>
                  </div>
                </div>
              </div>
              <!-- 燃气警报弹窗不显示时，占位dom -->
              <div class="bottom-dialog2" v-else></div>
            </div>
          </div>
        </div>
        <div class="item_5">
          <div class="item_5_head">
            <div class="item_5_head_line"></div>
            <div class="item_5_head_con">
              <img class="item_5_head_con_image" src="../../assets/large-screen/to_right.png" alt="logo">
              <span class="item_5_head_con_title">生命体征监测</span>
            </div>
          </div>
          <div class="item_5_table">
            <div class="item_5_table_labelline">
              <div class="life-column life-column-1">序号</div>
              <div class="life-column life-column-1">姓名</div>
              <div class="life-column life-column-2">房间号</div>
              <div class="life-column life-column-1">心率</div>
              <div class="life-column life-column-1">呼吸</div>
              <div class="life-column life-column-3">收缩压/舒张压</div>
              <div class="life-column life-column-1">脉率</div>
              <div class="life-column life-column-1">血糖</div>
              <div class="life-column life-column-1">血氧</div>
              <div class="label_7">床垫状态</div>
            </div>
            <div class="item_5_table_con" id="scrollDOM" @mouseover="handleMouseover" @mouseleave="handleMouseleave">
              <div class="item_5_table_line" v-for="(item, index) in monitorData" :key="index">
                <div class="life-column life-column-1">{{ index + 1 }}</div>
                <div class="life-column life-column-1">{{ item.realname }}</div>
                <div class="life-column life-column-2">{{ item.address }}</div>
                <div class="life-column life-column-1">
                  {{ item.sm ? item.sm.info ? item.sm.info.heart : '——' : '——' }}
                </div>
                <div class="life-column life-column-1">
                  {{ item.sm ? item.sm.info ? item.sm.info.breath : '——' : '——' }}
                </div>
                <div class="life-column life-column-3">
                  {{ item.health_patrol ? item.health_patrol.sp : '——' }}/{{ item.health_patrol ? item.health_patrol.dp :
                    '——' }}
                </div>
                <div class="life-column life-column-1">
                  {{ item.health_patrol ? item.health_patrol.pr : '——' }}
                </div>
                <div class="life-column life-column-1">
                  {{ item.health_patrol ? item.health_patrol.blood : '——' }}
                </div>
                <div class="life-column life-column-1">
                  {{ item.health_patrol ? item.health_patrol.spo2h : '——' }}
                </div>
                <div class="label_7" v-if="item.sm">
                  <div class="spot"
                    :style="{ backgroundColor: `${item.sm.online_status == 0 ? '#B6B6B6' : item.sm.online_status == 1 ? '#2BF159' : item.sm.online_status == 2 ? '#2BF159' : '#FFFFFF'}` }">
                  </div>
                  <span class="label_7_text"
                    :style="{ color: `${item.sm.online_status == 0 ? '#B6B6B6' : item.sm.online_status == 1 ? '#2BF159' : item.sm.online_status == 2 ? '#2BF159' : '#FFFFFF'}` }">
                    {{ `${item.sm.online_status == 0 ? '床垫离线' : item.sm.online_status == 1 ? '设备在线' :
                      item.sm.online_status == 2 ? '在床' : '离床'}` }}
                  </span>
                </div>
                <div class="label_7" v-else>
                  <span class="label_7_text">——</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body_right">
        <div class="item_6">
          <div class="item_1_head">
            <div class="item_1_head_line"></div>
            <div class="item_1_head_con">
              <img class="item_1_head_con_image" src="../../assets/large-screen/to_right.png" alt="logo">
              <span class="item_1_head_con_title">公告</span>
            </div>
          </div>
          <div class="item_6_con" id="noticeScroll" @mouseover="handleNoticeMouseover"
            @mouseleave="handleNoticeMouseleave">
            <div class="item_6_notice"
              :style="{ borderBottom: `${index != announcement.length - 1 ? '0.09259259259259259vh solid #01C2FF' : 'none'}` }"
              v-for="(item, index) in announcement" :key="index">
              <div class="item_6_notice_head">
                <span class="item_6_notice_head_title">{{ item.title }}</span>
                <span class="item_6_notice_head_time">{{ item.created_at }}</span>
              </div>
              <div class="item_6_notice_con">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div class="item_2">
          <div class="item_1_head">
            <div class="item_1_head_line"></div>
            <div class="item_1_head_con">
              <img class="item_1_head_con_image" src="../../assets/large-screen/to_right.png" alt="logo">
              <span class="item_1_head_con_title">今日派车记录</span>
            </div>
          </div>
          <div class="item_3_table">
            <div class="item_3_table_labelline">
              <div class="label_3">申请人</div>
              <div class="label_4">目的地</div>
              <div class="label_4_2">状态</div>
              <div class="label_5">出发时间-返回时间</div>
              <div class="label_6" style="justify-content: center;">操作</div>
            </div>
            <div class="item_3_table_con2">
              <div class="item_3_table_line" v-for="(item, index) in carApplication" :key="index">
                <div class="label_3 font-1">
                  <div v-if="item.status == 1" class="item-flex-row">
                    <div class="item-flex-row-spot"></div><span>{{ item.applicant }}</span>
                  </div>
                  <span v-else>{{ item.applicant }}</span>
                </div>
                <div class="label_4 font-2">{{ item.target }}</div>
                <div class="label_4_2 font-2">{{ item.status ? auditStatusDict[item.status].name : '' }}
                </div>
                <div class="label_5 font-2">
                  <span>{{ item.dispatch_time }}</span>
                  <span style="transform: scale(.5);">|</span>
                  <span>{{ item.return_time }}</span>
                </div>
                <div v-if="operationAuth" class="label_6 font-2">
                  <span class="btn-1" style="background-color: #01C2FF;"
                    @click="toOtherPath('/dispatch-vehicle/service')">处理</span>
                  <span class="btn-1" style="background-color: #00AA28;" @click="callVideo(item.member_id)">连线</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item_7">
          <div class="item_1_head">
            <div class="item_1_head_line"></div>
            <div class="item_1_head_con">
              <img class="item_1_head_con_image" src="../../assets/large-screen/to_right.png" alt="logo">
              <span class="item_1_head_con_title">叫餐</span>
            </div>
          </div>
          <div class="item_7_table">
            <div class="item_7_table_labelline">
              <div class="label_9" style="justify-content: center;">申请人</div>
              <div class="label_9">类型</div>
              <div class="label_10">名称</div>
              <div class="label_9" style="justify-content: center;">操作</div>
            </div>
            <div class="item_7_table_con">
              <div class="item_7_table_con_line" v-for="(item, index) in meal" :key="index">
                <div class="label_9" style="justify-content: center;">{{ item.member.realname }}</div>
                <div class="label_9">{{ item.type == 1 ? '早餐' : item.type == 2 ? '午餐' : '晚餐' }}</div>
                <div class="label_10">{{ item.content }}</div>
                <div v-if="operationAuth" class="label_11">
                  <span class="btn-1" style="background-color: #01C2FF;" @click="toOtherPath('/order/index')">处理</span>
                  <span class="btn-1" style="background-color: #00AA28;" @click="callVideo(item.member_id)">连线</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VideoCallDialog ref="videoCall" @closeCallDialog="closeCallDialog"></VideoCallDialog>

    <audio ref="audio" v-if="needVideo" loop :muted="mutedFlag" class="audio">
      <source src="../../assets/large-screen/warn.mp3">
    </audio>

    <audio ref="audio2" class="audio" v-if="needPromptVideo" :muted="mutedPromptFlag">
      <source src="../../assets/large-screen/prompt.mp3">
    </audio>
  </div>
</template>

<script>
import {
  getMonitorAPI,
  alarmResponseAPI
} from '@/api/large.js'

import {
  formatDate
} from '@/utils/common-fun'

import * as echarts from 'echarts';

import VideoCallDialog from '@/components/VideoCallDialog.vue'

const BaseOption = {
  title: {
    text: '',
    left: 'center',
    textStyle: {
      color: "#ffffff"
    }
  },
  tooltip: {
    trigger: 'item'
  },
  color: ['#07D1FA', '#0783FA', '#0691AD', '#0857A3', '#21abcd'],
  legend: {
    data: [],
    textStyle: {
      color: '#ffffff',
    },
    bottom: 0,
    padding: 0,
    itemGap: 5,
    type: 'scroll',
    itemHeight: 10,
    itemWidth: 15,
  },
  series: [{
    type: 'pie',
    radius: '70%',
    data: [],
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: 'rgba(0, 0, 0, 0.5)'
      }
    },
    labelLine: {
      show: false, //隐藏指示线
    },
    label: {
      show: false, //隐藏标示文字
    },
  }]
};

export default {
  name: 'large-screen',
  components: {
    VideoCallDialog,
  },
  data() {
    return {
      sosChannel: process.env.VUE_APP_SOS_CHANNEL,

      auditStatusDict: {
        1: {
          name: '待安排',
          type: 'warning',
          value: 1
        },
        2: {
          name: '已安排',
          type: 'primary',
          value: 2
        },
        3: {
          name: '审批通过',
          type: 'success',
          value: 3
        },
        4: {
          name: '审批驳回',
          type: 'danger',
          value: 4
        },
        5: {
          name: '已出营',
          type: 'primary',
          value: 5
        },
        6: {
          name: '已返回',
          type: 'success',
          value: 6
        }
      },
      duty: [], // 值班信息
      carApplication: [], // 派车信息
      announcement: [], // 公告
      gas: [], // 燃气警报
      meal: [], // 叫餐信息
      member: {}, // 人员统计
      sos: [], // 求救信息
      monitorData: [], // 检测数据
      sosHis: [], // 风险处理

      // 所有数据循环函数返回值
      dataTimer: null,

      // 左上角日期时间
      time: '2020-10-10 12:00:00',
      // 计算时间循环函数返回值
      timer: null,
      // 左上角星期
      week: '星期一',

      // echart 实例
      myChart_1: null,
      myChart_2: null,
      myChart_3: null,

      // sos 信息
      sosInfo: {},
      // sos 弹窗显示状态，房间sos报警
      sosShow: false,
      // sos 弹窗显示状态，其他硬件sos报警
      sosOtherShow: false,
      // sos 弹窗信息
      sosOtherInfo: {
        title: '',
        photo: '',
      },
      // 燃气警报 信息
      gasInfo: {},
      gasShow: false,

      // 弹窗 border 闪烁状态
      startFlag: false,
      // 闪烁循环函数
      startTimer: null,
      // 是否需要播放报警提示音
      needVideo: false,
      // 提示音是否静音的状态
      mutedFlag: true,
      // 开启警报音
      TurnOnSound: false,
      // 滚动dom循环函数
      scrollTimer: null,
      // 通知的滚动定时器
      noticeTimer: null,

      // sos报警类型
      SOStypeDict: {
        1: {
          name: 'SOS呼救'
        },
        2: {
          name: '燃气报警'
        },
        // 3: {
        //   name: '摔倒告警'
        // },
        4: {
          name: '离床预警'
        },
        5: {
          name: '派车提示',
        },
        6: {
          name: '点餐提示',
        },
        7: {
          name: '心率异常',
        },
        8: {
          name: '呼吸异常',
        }
      },

      // 是否需要播放提示音，点餐、叫车
      needPromptVideo: false,
      // 提示音是否静音状态，点餐、叫车
      mutedPromptFlag: false,
      // 界面的操作权限
      operationAuth: false,

      // 风险处理状态字典
      sosHisStatusDict: {
        0: {
          name: '待响应'
        },
        1: {
          name: '已响应'
        },
        2: {
          name: '已处理'
        }
      },

      // 是否为拨打电话中
      isCall: false,

    }
  },
  created() {
    this.operationAuth = this.getOperationAuth()
    this.getTime();
    this.getAllData();
    clearInterval(this.dataTimer); // 调试时，保存后会创建多个循环函数，此处清除就不会报错
    this.dataTimer = setInterval(() => {
      this.getAllData();
    }, 60000)
  },
  mounted() {
    this.connect();
    // setTimeout(() => {
    // 	this.playAudio();
    // }, 500)
  },
  beforeDestroy() {
    clearInterval(this.dataTimer);
    clearInterval(this.timer);
    clearInterval(this.scrollTimer);
    clearInterval(this.noticeTimer);
  },
  methods: {
    // 风险预警的处理按钮
    handleRiskWarning(dataId) {
      const data = JSON.stringify({
        type: 99
      })
      this.$mqtt.publish(this.sosChannel, data)
      alarmResponseAPI({
        id: dataId
      })
    },
    // 关闭了视频通话
    closeCallDialog() {
      this.isCall = false;
    },
    /**
     * @description 获取操作权限，返回值为 true 时，才有操作权限
     * @return {Bool} 
     **/
    getOperationAuth() {
      const roles = JSON.parse(sessionStorage.getItem('roleList'))
      const marker = 'Monitor'
      const result = roles.some(item => item.name == marker)
      return !result
    },
    back() {
      if (document.exitFullscreen) document.exitFullscreen()
      else if (document.msExitFullscreen) document.msExitFullscreen()
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen()
      else document.webkitCancelFullScreen()
      clearInterval(this.scrollTimer)
      clearInterval(this.noticeTimer)
      this.$router.back()
    },
    getAllData() {
      getMonitorAPI().then(res => {
        this.duty = res.duty;
        this.carApplication = res.carApplication;
        this.announcement = res.announcement;
        let gasArray = [];
        let sosHis = [];
        res.sosHis.forEach(item => {
          let result = this.memberSetAddress(item);
          sosHis.push(result)
        })
        this.sosHis = sosHis;

        res.gas.forEach(item => {
          let result = this.memberSetAddress(item);

          gasArray.push(result)
        })
        this.gas = gasArray;
        let sosArray = [];
        res.sos.forEach(item => {
          let result = this.memberSetAddress(item);
          sosArray.push(result)
        })
        this.sos = sosArray;
        this.member = res.member;
        let monitorDataArray = [];
        res.monitorData.forEach(item => {
          item.address = '';
          if (item.room) {
            item.address = `
							${item.room.floor.building.area.name ? item.room.floor.building.area.name : ''}
							${item.room.floor.building.name ? item.room.floor.building.name : ''}
							${item.room.floor.name ? item.room.floor.name : ''}
							${item.room.name ? item.room.name : ''}
							`
          }
          monitorDataArray.push(item)
        })
        this.monitorData = monitorDataArray;
        let meal = [];
        res.meal.forEach(item => {
          item.content = '';
          item.item.forEach(temp => {
            item.content += temp.meal.meal_name + ',';
          })
          item.content = item.content.substring(0, item.content.length - 1);
          meal.push(item);
        });
        this.meal = meal;
        this.setEchart()
        this.startScroll()
        this.noticeScroll()
      })
    },
    // member对象中配置地址
    memberSetAddress(item) {
      item.address = '';
      if (item.member) {
        // 存在member，从member中获取地址
        if (item.member.room) {
          item.address = `
						${item.member.room.floor.building.area.name ? item.member.room.floor.building.area.name : ''}
						${item.member.room.floor.building.name ? item.member.room.floor.building.name : ''}
						${item.member.room.floor.name ? item.member.room.floor.name : ''}
						${item.member.room.name ? item.member.room.name : ''}
						`
        }
      } else {
        // 不存在member，从device获取地址
        item.address = item.device ? item.device.location : '';
      }
      return item
    },
    // 滚动检测部分的dom
    startScroll() {
      let that = this;
      this.$nextTick(() => {
        clearInterval(that.scrollTimer)
        that.scrollTimer = setInterval(() => {
          const dom = document.querySelector('#scrollDOM');
          if (dom) {
            dom.scrollTop += 1;
            if ((dom.scrollTop + dom.clientHeight) >= dom.scrollHeight - 2) {
              dom.scrollTop = 0;
            }
          }
        }, 100)
      })
    },
    noticeScroll() {
      let that = this;
      this.$nextTick(() => {
        clearInterval(that.noticeTimer)
        that.noticeTimer = setInterval(() => {
          const dom2 = document.querySelector('#noticeScroll');
          dom2.scrollTop += 1;
          if (Math.ceil(dom2.scrollTop + dom2.clientHeight) >= dom2.scrollHeight - 2) {
            dom2.scrollTop = 0;
          }
        }, 100)
      })
    },
    // 生命体征检测的鼠标经过事件
    handleNoticeMouseover() {
      clearInterval(this.noticeTimer)
    },
    // 生命体征检测的鼠标离开事件
    handleNoticeMouseleave() {
      this.noticeScroll()
    },
    // 生命体征检测的鼠标经过事件
    handleMouseover() {
      clearInterval(this.scrollTimer)
    },
    // 生命体征检测的鼠标离开事件
    handleMouseleave() {
      this.startScroll()
    },
    // 设置饼图 series 数据
    setPieChartSeries(arr) {
      let results = []
      results[0] = { name: '床垫预警', value: arr[1] }
      results[1] = { name: '一键救护', value: arr[2] }
      results[2] = { name: '燃气预警', value: arr[3] }
      results[3] = { name: '公告学习', value: arr[6] }
      results[4] = { name: '订餐服务', value: arr[4] }
      results[5] = { name: '派车服务', value: arr[5] }
      return results
    },
    // 标题、图例等，type = 'today' || 'week' || 'month'
    setPieChartProfile(type, series) {
      let option = JSON.parse(JSON.stringify(BaseOption))
      // 设置标题
      let title = ''
      if (type == 'today') title = '当天数据'
      else if (type == 'week') title = '本周数据'
      else title = '本月数据'
      option.title.text = title
      // 设置 series 和图例
      option.series[0].data = series
      option.legend.data = series.map(item => item.name)
      // 配置传递给对应图表
      if (type == 'today') this.myChart_1.setOption(option)
      else if (type == 'week') this.myChart_2.setOption(option)
      else this.myChart_3.setOption(option)
    },
    // 设置echart图表
    setEchart() {
      this.$nextTick(() => {
        let chartDom_1 = document.getElementById('EchartId-1');
        let chartDom_2 = document.getElementById('EchartId-2');
        let chartDom_3 = document.getElementById('EchartId-3');

        if (this.myChart_1 == null) {
          this.myChart_1 = echarts.init(chartDom_1);
        }
        if (this.myChart_2 == null) {
          this.myChart_2 = echarts.init(chartDom_2);
        }
        if (this.myChart_3 == null) {
          this.myChart_3 = echarts.init(chartDom_3);
        }

        let data_1 = this.setPieChartSeries(this.member.six_day)
        let data_2 = this.setPieChartSeries(this.member.six_week)
        let data_3 = this.setPieChartSeries(this.member.six_month)

        this.setPieChartProfile('today', data_1)
        this.setPieChartProfile('week', data_2)
        this.setPieChartProfile('month', data_3)
      })
    },
    // 发起视频通话
    callVideo(id, dataId) {
      this.isCall = true;
      this.$refs.videoCall.open(id)
      if (dataId) {
        const data = JSON.stringify({
          type: 99
        })
        this.$mqtt.publish(this.sosChannel, data)
        alarmResponseAPI({
          id: dataId
        })
      }
    },
    // 连接MQTT
    connect() {
      let that = this;
      this.$mqtt.on('connect', () => console.log('连接成功'))
      this.$mqtt.subscribe(this.sosChannel, () => console.log('subscribe'))
      this.$mqtt.on('message', (topic, msgRes) => {
        let msg = JSON.parse(msgRes.toString())
        console.log(msg, '接受到sos报警信息')
        that.getAllData(); // 更新页面数据
        that.dataTimer = setInterval(() => { // 再次注册轮询
          that.getAllData();
        }, 60000)

        let type = msg.from;

        if ([1, 2, 3, 4].includes(type)) {
          // 播放声音
          that.playAudio();
          // 开启闪烁
          clearInterval(that.startTimer);
          that.startTimer = setInterval(() => {
            that.startFlag = !that.startFlag;
          }, 800);
          // 清除之前的定时器
          clearInterval(that.dataTimer); // 调试时，保存后会创建多个循环函数，此处清除就不会报错
        }
        switch (msg.type) {
          case 1:
            // 求救
            msg = msg.data;
            if (type == 1) {
              that.sosInfo = {
                sex: msg.sex == 1 ? '男' : msg.sex == 2 ? '女' : '未知',
                mobile: msg.mobile,
                photo: msg.photo,
                realname: msg.realname,
                medication: msg.medication,
                birthday: msg.birthday,
                disease: msg.disease,
                type: msg.type,
                title: 'sos紧急求助',
                userId: msg.id,
                alarmId: msg.alarm === null ? '' : msg.alarm.id,
                address: `
						${msg.room.floor.building.area.name ? msg.room.floor.building.area.name : ''}
						${msg.room.floor.building.name ? msg.room.floor.building.name : ''}
						${msg.room.floor.name ? msg.room.floor.name : ''}
						${msg.room.name ? msg.room.name : ''}
						`
              }
              that.sosShow = true;
              setTimeout(() => {
                // 一分钟后关闭弹窗
                that.sosShow = false;
              }, 60000)
            } else {
              that.sosOtherShow = true;
              that.sosOtherInfo.title = msg.location;
              that.sosOtherInfo.photo = msg.location_photo;
              setTimeout(() => {
                // 一分钟后关闭弹窗
                that.sosOtherShow = false;
              }, 60000)
              break;
            }
            break;
          case 2:
            // 燃气报警
            msg = msg.data;
            that.gasInfo = {
              address: `${msg.room.floor.building.area.name}${msg.room.floor.building.name}${msg.room.floor.name}${msg.room.name}`,
              realname: msg.realname,
              photo: msg.photo,
              type: msg.type,
            }
            that.gasShow = true;
            setTimeout(() => {
              // 一分钟后关闭弹窗
              that.gasShow = false;
            }, 60000)
            break;
          case 3:
            // 摔倒报警
            // msg = msg.data;
            // that.sosInfo = {
            //   sex: msg.sex == 1 ? '男' : msg.sex == 2 ? '女' : '未知',
            //   mobile: msg.mobile,
            //   photo: msg.photo,
            //   realname: msg.realname,
            //   medication: msg.medication,
            //   birthday: msg.birthday,
            //   disease: msg.disease,
            //   type: msg.type,
            //   title: '摔倒报警'
            // }
            // that.sosShow = true;
            // setTimeout(() => {
            //   // 一分钟后关闭弹窗
            //   that.sosShow = false;
            // }, 60000)
            break;
          case 4:
            // 离床警告
            msg = msg.data;
            that.sosInfo = {
              sex: msg.sex == 1 ? '男' : msg.sex == 2 ? '女' : '未知',
              mobile: msg.mobile,
              photo: msg.photo,
              realname: msg.realname,
              id_no: msg.id_no,
              birthday: msg.birthday,
              medical_history: msg.medical_history,
              type: msg.type,
              title: '离床时间预警'
            }
            that.sosShow = true;
            setTimeout(() => {
              // 一分钟后关闭弹窗
              that.sosShow = false;
            }, 60000)
            break;
          case 5:
            // 派车
            that.playPromptAudio();
            that.carApplication.unshift(msg);
            break;
          case 6:
            // 点餐
            that.playPromptAudio();
            that.meal.unshift(msg);
            break;
          case 7:
            // 心率警告
            msg = msg.data;
            that.sosInfo = {
              sex: msg.sex == 1 ? '男' : msg.sex == 2 ? '女' : '未知',
              mobile: msg.mobile,
              photo: msg.photo,
              realname: msg.realname,
              id_no: msg.id_no,
              birthday: msg.birthday,
              medical_history: msg.medical_history,
              type: msg.type,
              title: '心率预警'
            }
            that.sosShow = true;
            setTimeout(() => {
              // 一分钟后关闭弹窗
              that.sosShow = false;
            }, 60000)
            break;
          case 8:
            // 呼吸警告
            msg = msg.data;
            that.sosInfo = {
              sex: msg.sex == 1 ? '男' : msg.sex == 2 ? '女' : '未知',
              mobile: msg.mobile,
              photo: msg.photo,
              realname: msg.realname,
              id_no: msg.id_no,
              birthday: msg.birthday,
              medical_history: msg.medical_history,
              type: msg.type,
              title: '呼吸预警'
            }
            that.sosShow = true;
            setTimeout(() => {
              // 一分钟后关闭弹窗
              that.sosShow = false;
            }, 60000)
            break;
          // 取消报警
          case 99:
            that.sosShow = false
            that.gasShow = false
            that.sosOtherShow = false
            that.$refs.audio && that.$refs.audio.pause()
            that.getAllData()
            break
        }
      })
    },
    // 获取右上角日期时间展示
    getTime() {
      let that = this;
      let time = new Date();
      that.time = formatDate(time);
      let index = time.getDay();
      switch (index) {
        case 0:
          that.week = '星期日';
          break;
        case 1:
          that.week = '星期一';
          break;
        case 2:
          that.week = '星期二';
          break;
        case 3:
          that.week = '星期三';
          break;
        case 4:
          that.week = '星期四';
          break;
        case 5:
          that.week = '星期五';
          break;
        case 6:
          that.week = '星期六';
          break;
      }
      let diff = +time % 1000;
      if (diff == 0) {
        // 满足，隔一分钟执行一次
        clearInterval(that.timer);
        that.timer = setInterval(() => {
          that.getTime();
        }, 1000)
      } else {
        // 不满足，相差多少时间，间隔这么长时间后执行
        diff = 1000 - diff;
        clearInterval(that.timer)
        that.timer = setInterval(() => {
          that.getTime();
        }, diff)
      }
    },
    // 处理，前去其他路由页面
    toOtherPath(path) {
      if (!path) return

      let str = window.location.href
      let reg_exp = new RegExp("/large-screen");
      let res = str.replace(reg_exp, "");

      window.open(`${res}${path}`, '_blank');
    },
    // 播放警报提示音
    playAudio() {
      if (!this.TurnOnSound) return
      if (this.isCall) return
      this.needVideo = true;
      setTimeout(() => {
        this.$refs.audio && this.$refs.audio.play();
        this.mutedFlag = false;
      })
    },
    // 关闭警报提示音
    closeAudio() {
      this.needVideo = false;
      const data = JSON.stringify({
        type: 99
      })
      this.$mqtt.publish(this.sosChannel, data)
      setTimeout(() => {
        this.$refs.audio && this.$refs.audio.pause();
        this.mutedFlag = true;
      })
      clearInterval(this.startTimer);
    },
    // 播放点餐、叫车提示音
    playPromptAudio() {
      if (!this.TurnOnSound) return
      this.needPromptVideo = true;
      setTimeout(() => {
        this.$refs.audio2 && this.$refs.audio2.play();
        this.mutedPromptFlag = false;
      })
    },
    // 关闭点餐、叫车提示音
    closePromptAudio() {
      this.needPromptVideo = false;
      setTimeout(() => {
        this.$refs.audio2 && this.$refs.audio2.pause();
        this.mutedPromptFlag = true;
      })
    },
    // 关闭警告弹窗
    closePopup() {
      this.sosShow = false;
      this.sosOtherShow = false;
      this.gasShow = false;
      this.closeAudio();
    },

  },
}
</script>

<style scoped lang="less">
.main {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/large-screen/large-screen.-bg.jpg');
  background-size: 100% 100vh;
  user-select: none;
}

.head {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 7.4074074074074066vh;
  background-image: url('../../assets/large-screen/large-head.png');
  background-size: 100% 7.4074074074074066vh;

  .head_left {
    display: flex;
    position: absolute;
    top: 1.3vh;
    left: 2.7083333333333335vw;
  }

  .head_left_item {
    margin-right: 2.0833333333333335vw;
  }

  .head_left_date {
    font-size: 1.25vw;
    color: #45A2FF;
  }

  .head_left_time {
    font-size: 1.25vw;
    color: #FFF;
  }

  .head_title {
    position: relative;
    top: 0.5555555555555555vh;
    font-size: 1.7708333333333335vw;
    font-weight: 700;
    color: #FFFFFF;
    letter-spacing: 4px;
  }

  .head-right {
    position: absolute;
    top: 0.5555555555555555vh;
    right: 2.7083333333333335vw;
    color: #ffffff;
    font-size: 1.7708333333333335vw;
    cursor: pointer;
  }
}

.body {
  display: flex;
  flex-direction: row;
  position: relative;
  left: 1.3020833333333335vw;
  width: 97.39583333333334vw;
  height: 88.79629629629629vh;

  .body_left {
    width: 26.822916666666668vw;
    height: 88.79629629629629vh;
    display: flex;
    flex-direction: column;
  }

  .body_mid {
    width: 42.1875vw;
    height: 88.79629629629629vh;
    margin: 0 0.78125vw;
    display: flex;
    flex-direction: column;
  }

  .body_right {
    width: 26.822916666666668vw;
    height: 88.79629629629629vh;
    display: flex;
    flex-direction: column;
  }
}

.item_1 {
  width: 26.822916666666668vw;
  height: 28.703703703703702vh;
  box-shadow: inset 0px 0px 20px 0px #1658A7;
  background: rgba(8, 26, 48, 0.8);
}

.item_1_head {
  display: flex;
  flex-direction: row;
  margin-top: 0.18518518518518517vh;

  .item_1_head_line {
    width: 0.20833333333333334vw;
    height: 4.62962962962963vh;
    margin-right: 0.15625vw;
    background-color: #1B7EF2;
  }

  .item_1_head_con {
    width: 26.458333333333336vw;
    height: 4.62962962962963vh;
    background-color: rgba(27, 126, 242, .14);
    display: flex;
    align-items: center;

    .item_1_head_con_image {
      width: 0.63125vw;
      height: 1.122222222222222vh;
      margin-right: 0.9895833333333334vw;
    }

    .item_1_head_con_title {
      font-size: 1.25vw;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.87);
    }
  }
}

.item_2 {
  width: 26.822916666666668vw;
  height: 24.074074074074073vh;
  box-shadow: inset 0px 0px 20px 0px #1658A7;
  background: rgba(8, 26, 48, 0.8);
  margin: 1.3888888888888888vh 0;

  .item_2_table {
    width: 24.140625vw;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .item_2_table_labelline {
      width: 24.140625vw;
      height: 3.148148148148148vh;
      color: #159AFF;
      font-size: 0.7291666666666667vw;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .item_2_table_con {
      width: 24.140625vw;
      height: 16.296296296296294vh;
      overflow: scroll;
    }
  }
}

.item_2_table_line {
  width: 24.140625vw;
  min-height: 4.0740740740740735vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label_1 {
  width: 4.0234375vw;
  // line-height: 3.148148148148148vh;
}

.label_2 {
  width: 18.046875vw;
  // line-height: 3.148148148148148vh;
}

.label_2-2 {
  width: 10.046875vw;
  // line-height: 3.148148148148148vh;
}

.label_2-3 {
  width: 4vw;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item_3 {
  width: 26.822916666666668vw;
  height: 33.33333333333333vh;
  box-shadow: inset 0px 0px 20px 0px #1658A7;
  background: rgba(8, 26, 48, 0.8);


}

.item_3_table {
  width: 25.572916666666668vw;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  .item_3_table_labelline {
    width: 25.572916666666668vw;
    height: 3.148148148148148vh;
    font-size: 0.7291666666666667vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #01C2FF;
    background-color: #0F2B3F;
    border-radius: 0.20833333333333334vw;
    margin: 0.9259259259259258vh 0;
  }

  .item_3_table_con {
    width: 25.572916666666668vw;
    height: 21.296296296296294vh;
    overflow: scroll;
  }

  .item_3_table_con2 {
    width: 25.572916666666668vw;
    height: 13.296296296296294vh;
    overflow: scroll;
  }
}

.item_3_table_line {
  width: 25.572916666666668vw;
  height: 4.62962962962963vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0F2B3F;
  border-radius: 0.20833333333333334vw;
  margin-bottom: 0.9259259259259258vh;
}

.label_3 {
  width: 4.114583333333334vw;
  text-align: center;
}

.label_4 {
  width: 5.4375vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label_4_2 {
  width: 3.4375vw;
}

.text-c {
  text-align: center;
}

.label_5 {
  width: 7.500000000000002vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.label_6 {
  width: 5.520833333333334vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn-1 {
  width: 2.604166666666667vw;
  height: 2.4074074074074074vh;
  line-height: 2.4074074074074074vh;
  font-size: 0.9375vw;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  border-radius: 0.2604166666666667vw;
  cursor: pointer;
}

.btn-2 {
  width: 2.135416666666667vw;
  height: 1.9444444444444444vh;
  line-height: 1.9444444444444444vh;
  font-size: 0.7604166666666666vw;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  border-radius: 0.20833333333333334vw;
  cursor: pointer;

}

.item_4 {
  width: 42.1875vw;
  height: 54.07407407407407vh;
  background: rgba(8, 26, 48, 0.8);
  box-shadow: inset 0px 0px 20px 0px #1658A7;
  margin-bottom: 1.2962962962962963vh;
  background-image: url('../../assets/large-screen/large-center-bg2.png');
  background-size: 100% 54.07407407407407vh;
}

.item_4_con {
  width: 39.94791666666667vw;
  height: 49.166666666666664vh;
  margin: 3.518518518518518vh 0 1.3888888888888888vh 0.9375vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .item_4_con_left {
    width: 25.666666666666668vw;
    height: 38.79629629629629vh;
    background-image: linear-gradient(to bottom, rgba(8, 14, 30, 1), rgba(8, 14, 30, .74), rgba(2, 8, 20, .28));
  }

  .item_4_con_right {}
}

.item_4_head {
  display: flex;
  flex-direction: row;
  margin-top: 1.4814814814814814vh;

  .item_4_head_line {
    width: 0.20833333333333334vw;
    height: 4.62962962962963vh;
    margin-right: 0.15625vw;
    background-color: #1B7EF2;
    margin-left: 1.0416666666666667vw;
  }

  .item_4_head_con {
    width: 23.21875vw;
    height: 4.62962962962963vh;
    background-color: rgba(27, 126, 242, .14);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item_4_head_con_image {
      width: 0.63125vw;
      height: 1.122222222222222vh;
      margin-right: 0.9895833333333334vw;
    }

    .item_4_head_con_title {
      font-size: 1.25vw;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.87);
    }
  }
}

.table_label {
  width: 24.625vw;
  height: 3.148148148148148vh;
  background-color: #023164;
  margin-top: 1.6666666666666665vh;
  margin-bottom: 0.4629629629629629vh;
  display: flex;
  flex-direction: row;
  font-size: 0.7291666666666667vw;
  font-weight: 500;
  color: #159AFF;
  line-height: 3.148148148148148vh;
  margin-left: 0.5208333333333334vw;

}

.table_line {
  width: 24.625vw;
  min-height: 2.7777777777777777vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.625vw;
  font-weight: 400;
  color: #FFFFFF;
  background-color: #0F2B3F;
  margin-bottom: 0.37037037037037035vh;
}

.label_12 {
  width: 4.739583333333334vw;
  text-align: center;
}

.label_13 {
  width: 10.9375vw;
  text-align: center;
}

.label_13-2 {
  width: 4vw;
  text-align: center;
}

.label_14 {
  width: 4.479166666666667vw;
  display: flex;
}

.table_con {
  width: 24.625vw;
  height: 26.222222222222221vh;
  overflow: scroll;
  margin-left: 0.5208333333333334vw;
}

.item_5 {
  width: 42.1875vw;
  height: 33.33333333333333vh;
  background: rgba(8, 26, 48, 0.8);
  box-shadow: inset 0px 0px 20px 0px #1658A7;

  .item_5_table {
    width: 40.078125vw;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .item_5_table_labelline {
      width: 40.078125vw;
      height: 3.7037037037037033vh;
      font-size: 0.7291666666666667vw;
      display: flex;
      flex-direction: row;
      line-height: 3.7037037037037033vh;
      color: #01C2FF;
      background-color: #0F2B3F;
      border-radius: 0.20833333333333334vw;
      margin: 0.9259259259259258vh 0;
    }

    .item_5_table_con {
      width: 40.078125vw;
      height: 21.296296296296294vh;
      overflow: scroll;
    }
  }
}

.item_5_table_line {
  width: 40.078125vw;
  min-height: 3.7037037037037033vh;
  background-color: #0F2B3F;
  border-radius: 0.20833333333333334vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.9259259259259258vh;
  color: #FFFFFF;
  font-size: 0.625vw;
}

.life-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 3.7037037037037033vh;
}

.life-column-1 {
  width: 3vw;
}

.life-column-2 {
  width: 8vw;
  text-align: center;
}

.life-column-3 {
  width: 7vw;
  text-align: center;
}

.label_7 {
  width: 3vw;
  height: 3.7037037037037033vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.label_8 {
  width: 8vw;
  min-height: 3.7037037037037033vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.item_5_head {
  display: flex;
  flex-direction: row;
  margin-top: 0.18518518518518517vh;

  .item_5_head_line {
    width: 0.20833333333333334vw;
    height: 4.62962962962963vh;
    margin-right: 0.15625vw;
    background-color: #1B7EF2;
  }

  .item_5_head_con {
    width: 42.1875vw;
    height: 4.62962962962963vh;
    background-color: rgba(27, 126, 242, .14);
    display: flex;
    align-items: center;

    .item_5_head_con_image {
      width: 0.63125vw;
      height: 1.122222222222222vh;
      margin-right: 0.9895833333333334vw;
    }

    .item_5_head_con_title {
      font-size: 1.25vw;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.87);
    }
  }
}

.item_6 {
  width: 26.822916666666668vw;
  height: 28.703703703703702vh;
  box-shadow: inset 0px 0px 20px 0px #1658A7;
  background: rgba(8, 26, 48, 0.8);
}

.item_6_con {
  width: 26.822916666666668vw;
  height: 20.648148148148145vh;
  overflow: scroll;
}

.item_6_notice {
  width: 23.385416666666668vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 1vh;

  .item_6_notice_head {
    width: 23.385416666666668vw;
    height: 6.666666666666666vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .item_6_notice_head_title {
      font-size: 1vw;
      font-weight: 700;
      color: #FFFFFF;
    }

    .item_6_notice_head_time {
      font-size: 0.8333333333333334vw;
      font-weight: 400;
      color: #FFFFFF;
    }
  }

  .item_6_notice_con {
    width: 23.385416666666668vw;
    min-height: 13.333333333333332vh;
    display: flex;
    flex-wrap: wrap;
    line-height: 3.333333333333333vh;
    font-size: 0.68vw;
    font-weight: 700;
    color: #FFFFFF;
  }
}

.item_7 {
  width: 26.822916666666668vw;
  height: 33.33333333333333vh;
  background: rgba(8, 26, 48, 0.8);
  box-shadow: inset 0px 0px 20px 0px #1658A7;

  .item_7_table {
    width: 25.78125vw;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .item_7_table_labelline {
      width: 25.78125vw;
      height: 3.148148148148148vh;
      font-size: 0.7291666666666667vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #01C2FF;
      background-color: #0F2B3F;
      border-radius: 0.20833333333333334vw;
      margin: 0.9259259259259258vh 0;
    }

    .item_7_table_con {
      width: 25.78125vw;
      height: 21.296296296296294vh;
      overflow: scroll;

      .item_7_table_con_line {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 25.78125vw;
        height: 3.148148148148148vh;
        margin-bottom: 0.9259259259259258vh;
        font-size: 0.625vw;
        font-weight: 400;
        color: #FFFFFF;
        background-color: #0F2B3F;
        border-radius: 0.20833333333333334vw;
      }
    }
  }
}

.label_9 {
  width: 5.15625vw;
  height: 3.7037037037037033vh;
  line-height: 3.7037037037037033vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label_10 {
  width: 10vw;
  height: 3.7037037037037033vh;
  line-height: 3.7037037037037033vh;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label_11 {
  width: 5.46875vw;
  height: 3.7037037037037033vh;
  line-height: 3.7037037037037033vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.font-1 {
  font-weight: 500;
  font-size: 0.625vw;
  color: #FFF;
}

.font-2 {
  font-weight: 500;
  color: #ffffff;
  font-size: 0.625vw;
}

.top-dialog2 {
  width: 13.59375vw;
  height: 18.61111111111111vh;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.other-sos {
  width: 13.181818181818182vw;
  height: 18.63888888888889vh;
  background-image: url('../../assets/large-screen/sosBg_2.png');
  background-size: 100% 18.63888888888889vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .other-sos-con {
    width: 12.424242424242424vw;
    height: 16.48148148148148vh;
    // background-color: #FFFFFF;
    border-radius: 0.35353535353535354vw;
    padding: 0 0.7575757575757576vw;
    box-sizing: border-box;

    .other-sos-head {
      width: 11.06060606060606vw;
      height: 2.5925925925925926vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .other-sos-head-logo {
        width: 0.5934343434343434vw;
        height: 1.2962962962962963vh;
      }

      .other-sos-head-logo2 {
        width: 0.8080808080808081vw;
        height: 1.4814814814814814vh;
      }

      .other-sos-head-text {
        font-size: 0.7070707070707071vw;
        font-weight: 500;
        color: #4C4C4C;
        margin-left: 0.15151515151515152vw;
      }

      .other-sos-head-text2 {
        font-size: 0.8080808080808081vw;
        font-weight: 500;
        color: #4C4C4C;
        margin-left: 0.15151515151515152vw;
      }
    }
  }

  .other-sos-local {
    width: 11.06060606060606vw;
    height: 9.351851851851851vh;
    border-radius: 0.20202020202020202vw;
  }

}

.other-sos-box {
  width: 11.06060606060606vw;
  margin: 0 0.7575757575757576vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: red;

  .other-sos-box-line {
    width: 11.06060606060606vw;
    height: 2.5925925925925926vh;
  }
}

.top-dialog {
  width: 13.59375vw;
  height: auto;
  background-image: url('../../assets/large-screen/sos.png');
  background-size: 100% auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .top-dialog-con {
    width: 12.8125vw;
    height: auto;
    background-color: #FFFFFF;
    border-radius: 0.36458333333333337vw;
    display: flex;
    flex-direction: column;
    margin-top: 1.2962962962962963vh;

    .top-dialog-con-head {
      height: 2.7777777777777777vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .top-dialog-con-head-logo {
        width: 0.8333333333333334vw;
        height: 1.4814814814814814vh;
        margin: 0 0.2604166666666667vw 0 0.78125vw;
      }

      .top-dialog-con-head-text {
        font-size: 0.625vw;
        font-weight: 500;
        color: #4C4C4C;
      }
    }

    .top-dialog-con-line {
      height: 4.0740740740740735vh;
      display: flex;
      flex-direction: row;
      align-items: center;

      .top-dialog-con-line-logo {
        width: 1.5104166666666667vw;
        height: 2.685185185185185vh;
        margin: 0 0.5208333333333334vw 0 0.8333333333333334vw;
        border-radius: 100%;
      }

      .top-dialog-con-line-name {
        font-size: 0.7291666666666667vw;
        font-weight: 500;
        color: #4C4C4C;
      }
    }

    .top-dialog-con-desc {
      width: 11.145833333333334vw;
      height: 2.222222222222222vh;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      .top-dialog-con-desc-text {
        font-size: 0.5208333333333334vw;
        font-weight: 500;
        color: #4C4C4C;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.bottom-dialog2 {
  width: 13.59375vw;
  height: 10.462962962962962vh;
  margin-top: 10.37037037037037vh;
}

.bottom-dialog {
  width: 13.59375vw;
  height: 13.462962962962962vh;
  margin-top: 10.37037037037037vh;
  background-image: url('../../assets/large-screen/gas.png');
  background-size: 100% 13.462962962962962vh;
  display: flex;
  align-items: center;

  .bottom-dialog-con {
    width: 12.8125vw;
    height: 10.962962962962963vh;
    background-color: #FFFFFF;
    border-radius: 0.36458333333333337vw;
    margin-left: 0.36458333333333337vw;

    .bottom-dialog-con-head {
      height: 2.7777777777777777vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .bottom-dialog-con-head-logo {
        width: 0.8333333333333334vw;
        height: 1.4814814814814814vh;
        margin: 0 0.2604166666666667vw 0 0.78125vw;
      }

      .bottom-dialog-con-head-text {
        font-size: 0.625vw;
        font-weight: 500;
        color: #4C4C4C;
      }
    }

    .bottom-dialog-con-line {
      height: 4.0740740740740735vh;
      display: flex;
      flex-direction: row;
      align-items: center;

      .bottom-dialog-con-line-logo {
        width: 1.5104166666666667vw;
        height: 2.685185185185185vh;
        margin: 0 0.5208333333333334vw 0 0.8333333333333334vw;
        border-radius: 100%;
      }

      .bottom-dialog-con-line-name {
        font-size: 0.7291666666666667vw;
        font-weight: 500;
        color: #4C4C4C;
      }
    }

    .bottom-dialog-con-desc {
      width: 11.145833333333334vw;
      height: 3.222222222222222vh;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden; //溢出内容隐藏
      text-overflow: ellipsis; //文本溢出部分用省略号表示
      display: -webkit-box; //特别显示模式
      line-clamp: 2;

      .bottom-dialog-con-desc-text {
        font-size: 0.5208333333333334vw;
        font-weight: 500;
        color: #4C4C4C;
      }
    }
  }
}

.spot {
  width: 0.2604166666666667vw;
  height: 0.4629629629629629vh;
  border-radius: 100%;
  margin-right: 0.15625vw;
}

.label_7_text {
  font-size: 0.625vw;
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.row {
  display: flex;
  flex-direction: row;
}

.star-bor {
  background-color: #FFFFFF;
  box-shadow: inset 0px 0px 20px 0px red;
  transition: all linear .3s;
}

.static-bor {
  background-color: #FFFFFF;
  box-shadow: inset 0px 0px 20px 0px transparent;
  transition: all linear .3s;
}

.audio {
  // position: relative;
  // top: -9999px;
}

.row-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.open-sound {
  color: #ffffff;
  font-size: 0.6060606060606061vw;
}

.echart-box {
  width: 26.822916666666668vw;
  height: 21.888888888888886vh;
  padding-top: 1vh;
}

.pie-chart {
  height: 19.888888888888886vh;
  width: 26vw;
  margin: 0 auto;
}

.item-flex-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-flex-row-spot {
  width: 0.30303030303030304vw;
  height: 0.5555555555555555vh;
  background-color: red;
  border-radius: 100%;
  margin-right: 0.30303030303030304vw;
}
</style>

<style>
#large-screen-index .body .body_left .item_1 .el-carousel .el-carousel__button {
  width: 0.30303030303030304vw;
  height: 0.5555555555555555vh;
  border: none;
  border-radius: 100px;
  background-color: #114688;
}

#large-screen-index .body .body_left .item_1 .el-carousel .is-active .el-carousel__button {
  width: 0.9090909090909091vw;
  height: 0.5555555555555555vh;
  border-radius: 100px;
  background: #0783FA;
}
</style>